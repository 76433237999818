import { v4 as uuidv4 } from "uuid";
//const translations = JSON.parse(da);
// export const FolderData = [
//   {
//     id: "ATTEST",
//     fileType: "pdf",
//     description: "",
//     keys: [],
//     multiple: true,
//     name: "Tingbogsattest",
//     sourceLink: "",
//     sourceName: "",
//   },
//   {
//     id: "ACT",
//     fileType: "pdf",
//     description: "",
//     keys: [
//       {
//         example: "3_F_198",
//         id: "DokumentFilnavnTekst",
//         text: "Dokument filnavn",
//       },
//     ],
//     multiple: true,
//     name: "Akt",
//     sourceLink: "",
//     sourceName: "",
//   },
// ];

export const Folder = [
  {
    id: "FOLDER",
    keys: [],
    children: [],
    separator: " ",
    uuid: uuidv4(),
  },
];

const PlanKeys = [
  {
    id: "type",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_TYPE",
    example: "municipality",
  },
  {
    id: "planId",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_PLAN_ID",
    example: "10466010",
  },
  {
    id: "planName",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_PLAN_NAME",
    example: "Brogårdsvej og Gentoftegade",
  },
  {
    id: "planNumber",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_PLAN_NUMBER",
    example: "5.B46",
  },
  {
    id: "zone",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_ZONE",
    example: "1",
  },
  {
    id: "district",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_DISTRICT",
    example: "Distrikt",
  },
  {
    id: "statusCode",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_STATUS_CODE",
    example: "V",
  },
  {
    id: "statusText",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_STATUS_TEXT",
    example: "Vedtaget",
  },
  {
    id: "typeCode",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_TYPE_CODE",
    example: " 10.1",
  },
  {
    id: "typeText",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_TYPE_TEXT",
    example: "Kommuneplanrammer",
  },
  {
    id: "generalUsageCode",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_GENERAL_USAGE_CODE",
    example: "11",
  },
  {
    id: "generalUsageText",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_GENERAL_USAGE_TEXT",
    example: "Boligområde",
  },
  {
    id: "adoptionDate",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_ADOPTION_DATE",
    example: "2021-08-30",
  },
  {
    id: "proposalDate",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_PROPOSAL_DATE",
    example: "2021-04-26",
  },
  {
    id: "terminationDate",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_TERMINATION_DATE",
    example: "2021-04-26",
  },
  {
    id: "publicationDate",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_PUBLICATION_DATE",
    example: "2021-04-26",
  },
  {
    id: "startDate",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_START_DATE",
    example: "2021-04-26",
  },
  {
    id: "endDate",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_END_DATE",
    example: "2021-04-26",
  },
  {
    id: "lastUpdated",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_LAST_UPDATED",
    example: "2021-04-26",
  },
  {
    id: "maxBuildingPercentage",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_MAX_BUILDING_PERCENTAGE",
    example: "25",
  },
  {
    id: "maxBuildingPercentageCalculation",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_MAX_BUILDING_PERCENTAGE_CALCULATION",
    example: "2",
  },
  {
    id: "maxBuiltArea",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_MAX_BUILDING_AREA",
    example: "40",
  },
  {
    id: "maxBuildingVolume",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_MAX_BUILDING_VOLUME",
    example: "50",
  },
  {
    id: "maxNumberOfFloors",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_MAX_NUMBER_OF_FLOORS",
    example: "2",
  },
  {
    id: "maxHeight",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_MAX_HEIGHT",
    example: "28",
  },
  {
    id: "minSize",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_MINIMUM_SIZE",
    example: "132",
  },
  {
    id: "maxBuildingPercentagePerProperty",
    text: "TEMPLATE_BUILDER_PLAN_KEYS_MAX_BUILDING_PERCENT_PER_PROPERTY",
    example: "44",
  },
];
const SoilConKeys = [
  {
    id: "ownersGuildCode",
    text: "TEMPLATE_BUILDER_CADASTRE",
    example: "0001",
  },
  {
    id: "matrikelNumber",
    text: "TEMPLATE_BUILDER_CADASTRAL_DISTRICT_IDENTIFIER",
    example: "0001",
  },
];

const CompanyKeys = [
  {
    id: "cvr",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_CVR_NUMBER",
    example: "41314281",
  },
  {
    id: "advertisingProtected",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_ADVERTISING_PROTECTED",
    example: "true",
  },
  {
    id: "capital",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_CAPITAL",
    example: "1000000",
  },
  {
    id: "email",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_EMAIL",
    example: "hello@estaid.dk",
  },
  {
    id: "establishedAt",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_ESTABLISHED",
    example: "2020-04-23",
  },
  {
    id: "lastUpdated",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_LAST_UPDATED",
    example: "2022-05-02",
  },
  {
    id: "mainBranchCode",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_MAIN_INDUSTRY_CODE",
    example: "582900",
  },
  {
    id: "mainBranchText",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_MAIN_INDUSTRY_TEXT",
    example: "Anden udgivelse af software",
  },
  {
    id: "name",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_COMPANY_NAME",
    example: "Estaid ApS",
  },
  {
    id: "phoneNumber",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_PHONE",
    example: "51842410",
  },
  {
    id: "status",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_STATUS",
    example: "NORMAL",
  },
  {
    id: "typeText",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_TYPE_TEXT",
    example: "Anpartsselskab",
  },
  {
    id: "zipCode",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_ZIP",
    example: "1253",
  },
  {
    id: "street",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_STREET",
    example: "Toldbodgade",
  },
  {
    id: "city",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_CITY",
    example: "København K",
  },
  {
    id: "countryCode",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_COUNTRY_CODE",
    example: "DK",
  },
  {
    id: "door",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_DOOR",
    example: "th",
  },
  {
    id: "floor",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_FLOOR",
    example: "1",
  },
  {
    id: "houseLetterFrom",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_HOUSE_LETTER_FROM",
    example: "C",
  },
  {
    id: "houseLetterTo",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_HOUSE_LETTER_TO",
    example: "D",
  },
  {
    id: "houseNumberFrom",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_HOUSE_NUMBER_FROM",
    example: "51",
  },
  {
    id: "houseNumberTo",
    text: "TEMPLATE_BUILDER_COMPANY_KEYS_HOUSE_NUMBER_TO",
    example: "52",
  },
];
const DLRAct = [
  {
    id: "DokumentFilnavnTekst",
    text: "TEMPLATE_BUILDER_DLRACT_DOCUMENT_FILENAME",
    example: "3_F_198",
  },
];
const DokumentAliasIdentifikator = [
  {
    id: "DokumentAliasIdentifikator",
    text: "TEMPLATE_BUILDER_DOCUMENT_ALIAS_IDENTIFIER",
    example: "01.01.2022-0000000000",
  },
];
const DLRAttachmentReference = [
  {
    id: "BilagReference",
    text: "TEMPLATE_BUILDER_ATTACHMENT_REFERENCE",
    example: "01.01.2022-0000000000",
  },
];
const DLRAdkomst = [
  {
    id: "DokumentAliasIdentifikator",
    text: "TEMPLATE_BUILDER_DOCUMENT_ALIAS_IDENTIFIER",
    example: "01.01.2022-0000000000",
  },
];
const DLREasement = [
  {
    id: "DokumentAliasIdentifikator",
    text: "TEMPLATE_BUILDER_DOCUMENT_ALIAS_IDENTIFIER",
    example: "01.01.2022-0000000000",
  },
  {
    id: "PrioritetNummer",
    text: "TEMPLATE_BUILDER_PRIORITY",
    example: "1",
  },
];
const DLRLiability = [
  {
    id: "DokumentAliasIdentifikator",
    text: "TEMPLATE_BUILDER_DOCUMENT_ALIAS_IDENTIFIER",
    example: "01.01.2022-0000000000",
  },
  {
    id: "PrioritetNummer",
    text: "TEMPLATE_BUILDER_PRIORITY",
    example: "1",
  },
  {
    id: "HaeftelseType",
    text: "TEMPLATE_BUILDER_MORTGAGE_TYPE",
    example: "realkreditpantebrev",
  },
  {
    id: "BeloebVaerdi",
    text: "TEMPLATE_BUILDER_VALUE",
    example: "980000",
  },
  {
    id: "ValutaKode",
    text: "TEMPLATE_BUILDER_CURRENCY",
    example: "DKK",
  },
];
const LiabilityNumber = [
  {
    id: "LiabilityNumber",
    text: "TEMPLATE_BUILDER_LIABILITY_NUMBER",
    example: "1",
  },
];
const EnergyLabelKeys = [
  {
    id: "propertyNumber",
    text: "TEMPLATE_BUILDER_ENERGY_LABEL_KEYS_PROPERTY_NUMBER",
    example: "574954",
  },
  {
    id: "buildingNumber",
    text: "TEMPLATE_BUILDER_ENERGY_LABEL_KEYS_BULDING_NUMBER",
    example: "1",
  },
  {
    id: "serialNumber",
    text: "TEMPLATE_BUILDER_ENERGY_LABEL_KEYS_SERIAL_NUMBER",
    example: "311258828",
  },
  {
    id: "classification",
    text: "TEMPLATE_BUILDER_ENERGY_LABEL_KEYS_CLASSIFICATION",
    example: "C",
  },
  {
    id: "statusCode",
    text: "TEMPLATE_BUILDER_ENERGY_LABEL_KEYS_STATUS_CODE",
    example: "1",
  },
  {
    id: "statusText",
    text: "TEMPLATE_BUILDER_ENERGY_LABEL_KEYS_STATUS_TEXT",
    example: "VALID",
  },
  {
    id: "basedOn",
    text: "TEMPLATE_BUILDER_ENERGY_LABEL_KEYS_BASED_ON",
    example: "CalculatedConsumption",
  },
  {
    id: "usage",
    text: "TEMPLATE_BUILDER_ENERGY_LABEL_KEYS_USAGE",
    example: "NonResidental",
  },
  {
    id: "consultantName",
    text: "TEMPLATE_BUILDER_ENERGY_LABEL_KEYS_CONSULTANT",
    example: "Jens Jensen",
  },
  {
    id: "companyName",
    text: "TEMPLATE_BUILDER_ENERGY_LABEL_KEYS_COMPANY_NAME",
    example: "Bang & Beenfeldt A/S",
  },
  {
    id: "companyNumber",
    text: "TEMPLATE_BUILDER_ENERGY_LABEL_KEYS_COMPANY_NUMBER",
    example: "600272",
  },
  {
    id: "validFrom",
    text: "TEMPLATE_BUILDER_ENERGY_LABEL_KEYS_VALID_FROM",
    example: "2017-07-04",
  },
  {
    id: "validTo",
    text: "TEMPLATE_BUILDER_ENERGY_LABEL_KEYS_VALID_TO",
    example: "2027-07-04",
  },
];
const AnnualCompanyReportKeys = [
  {
    id: "balance",
    text: "TEMPLATE_BUILDER_ANNUAL_COMPANY_REPORT_KEYS_BALANCE",
    example: "1000000",
  },
  {
    id: "caseNumber",
    text: "TEMPLATE_BUILDER_ANNUAL_COMPANY_REPORT_KEYS_CASE_NUMBER",
    example: "X21-FB-94-GL",
  },
  {
    id: "ebit",
    text: "TEMPLATE_BUILDER_ANNUAL_COMPANY_REPORT_KEYS_EBIT",
    example: "1088694",
  },
  {
    id: "equity",
    text: "TEMPLATE_BUILDER_ANNUAL_COMPANY_REPORT_KEYS_EQUITY",
    example: "5555555",
  },
  {
    id: "grossProfit",
    text: "TEMPLATE_BUILDER_ANNUAL_COMPANY_REPORT_KEYS_GROSS_PROFIT",
    example: "111111",
  },
  {
    id: "netIncome",
    text: "TEMPLATE_BUILDER_ANNUAL_COMPANY_REPORT_KEYS_NET_INCOME",
    example: "849651",
  },
  {
    id: "turnover",
    text: "TEMPLATE_BUILDER_ANNUAL_COMPANY_REPORT_KEYS_TURNOVER",
    example: "222222",
  },
  {
    id: "year",
    text: "TEMPLATE_BUILDER_ANNUAL_COMPANY_REPORT_KEYS_YEAR",
    example: "2020",
  },
];

export const ACT = {
  id: "ACT",
  name: "TEMPLATE_BUILDER_ACT",
  description: "",
  keys: [...DLRAct],
  multiple: true,
  fileType: "pdf",
  sourceName: "",
  sourceLink: "",
};
export const ACT_DEED = {
  id: "ACT_DEED",
  name: "TEMPLATE_BUILDER_DEED_IN_ACT",
  description: "",
  keys: [...DLRAct],
  multiple: false,
  fileType: "pdf",
  sourceName: "",
  sourceLink: "",
};
export const ATTACHMENTS = {
  id: "ATTACHMENTS",
  name: "TEMPLATE_BUILDER_ATTACHMENTS",
  description: "",
  keys: [...DokumentAliasIdentifikator, ...DLRAttachmentReference],
  multiple: true,
  fileType: "pdf",
  sourceName: "",
  sourceLink: "",
};
export const ATTEST = {
  id: "ATTEST",
  name: "TEMPLATE_BUILDER_REGISTRY_CERTIFICATE",
  description: "",
  keys: [],
  multiple: true,
  fileType: "pdf",
  sourceName: "",
  sourceLink: "",
};
export const DEED = {
  id: "DEED",
  name: "TEMPLATE_BUILDER_DEED",
  description: "",
  keys: [...DLRAdkomst],
  multiple: true,
  fileType: "pdf",
  sourceName: "",
  sourceLink: "",
};
export const ENDORSEMENT_DEED = {
  id: "ENDORSEMENT_DEED",
  name: "TEMPLATE_BUILDER_ENDORSEMENT_ON_DEED",
  description: "",
  keys: [...DLRAdkomst],
  multiple: true,
  fileType: "pdf",
  sourceName: "",
  sourceLink: "",
};
export const ENTITLEMENTS = {
  id: "ENTITLEMENT",
  name: "TEMPLATE_BUILDER_ENTITLEMENTS",
  description: "",
  keys: [...DLRAdkomst],
  multiple: true,
  fileType: "pdf",
  sourceName: "",
  sourceLink: "",
};
export const EASEMENTS = {
  id: "EASEMENTS",
  name: "TEMPLATE_BUILDER_EASEMENTS",
  description: "",
  keys: [...DLREasement],
  multiple: true,
  fileType: "pdf",
  sourceName: "",
  sourceLink: "",
};
export const ENDORSEMENT_EASEMENTS = {
  id: "ENDORSEMENT_EASEMENTS",
  name: "TEMPLATE_BUILDER_ENDORSEMENT_EASEMENTS",
  description: "",
  keys: [...DLREasement],
  multiple: true,
  fileType: "pdf",
  sourceName: "",
  sourceLink: "",
};
export const LIABILITIES = {
  id: "LIABILITIES",
  name: "TEMPLATE_BUILDER_LIABILITIES",
  description: "",
  keys: [...DLRLiability],
  multiple: true,
  fileType: "pdf",
  sourceName: "",
  sourceLink: "",
};
export const ENDORSEMENT_LIABILITIES = {
  id: "ENDORSEMENT_LIABILITIES",
  name: "TEMPLATE_BUILDER_ENDORSEMENT_LIABILITIES",
  description: "",
  keys: [...DLRLiability, ...LiabilityNumber],
  multiple: true,
  fileType: "pdf",
  sourceName: "",
  sourceLink: "",
};
export const AREAS = {
  id: "AREAS",
  name: "TEMPLATE_BUILDER_AREA",
  description: "",
  keys: [],
  multiple: false,
  fileType: "xlsx",
  sourceName: "",
  sourceLink: "",
};
export const OWNERS_SHEET = {
  id: "OWNERS_SHEET",
  name: "TEMPLATE_BUILDER_OWNERS_SHEET",
  description: "",
  keys: [],
  multiple: false,
  fileType: "xlsx",
  sourceName: "",
  sourceLink: "",
};
export const OWNERS_MORTGAGES = {
  id: "OWNERS_MORTGAGES",
  name: "TEMPLATE_BUILDER_OWNERS_MORTGAGES",
  description: "",
  keys: [],
  multiple: false,
  fileType: "xlsx",
  sourceName: "",
  sourceLink: "",
};
export const CORPORATE_DIAGRAM = {
  id: "OWNERS_DIAGRAM",
  name: "TEMPLATE_BUILDER_CORPORATE_DIAGRAM",
  description: "",
  keys: [...CompanyKeys],
  multiple: true,
  fileType: "png",
  sourceName: "",
  sourceLink: "",
};
export const BBR_SHEET = {
  id: "BBR_SHEET",
  name: "TEMPLATE_BUILDER_BBR_SHEET",
  description: "",
  keys: [],
  multiple: false,
  fileType: "pdf",
  sourceName: "",
  sourceLink: "",
};
export const ENERGY_LABEL = {
  id: "ENERGY_LABEL",
  name: "TEMPLATE_BUILDER_ENERGY_LABEL",
  description: "",
  keys: [...EnergyLabelKeys],
  multiple: true,
  fileType: "pdf",
  sourceName: "",
  sourceLink: "",
};
export const ANNUAL_COMPANY_REPORT = {
  id: "ANNUAL_COMPANY_REPORT",
  name: "TEMPLATE_BUILDER_ANNUAL_COMPANY_REPORT",
  description: "",
  keys: [...AnnualCompanyReportKeys, ...CompanyKeys],
  multiple: true,
  fileType: "pdf",
  sourceName: "",
  sourceLink: "",
};
export const LOCAL_PLAN = {
  id: "LOCAL_PLAN",
  name: "TEMPLATE_BUILDER_LOCAL_PLAN",
  description: "",
  keys: [...PlanKeys],
  multiple: true,
  fileType: "pdf",
  sourceName: "",
  sourceLink: "",
};
export const MUNICIPALITY_PLAN = {
  id: "MUNICIPALITY_PLAN",
  name: "TEMPLATE_BUILDER_MUNICIPALITY_PLAN",
  description: "",
  keys: [...PlanKeys],
  multiple: true,
  fileType: "pdf",
  sourceName: "",
  sourceLink: "",
};
export const SOIL_CON = {
  id: "SOIL_CON",
  name: "TEMPLATE_BUILDER_SOIL_CON",
  description: "",
  keys: [...SoilConKeys],
  multiple: false,
  fileType: "pdf",
  sourceName: "",
  sourceLink: "",
};

export const ALL_DOCUMENTS = [
  ACT,
  ACT_DEED,
  ATTACHMENTS,
  ATTEST,
  DEED,
  ENDORSEMENT_DEED,
  EASEMENTS,
  ENDORSEMENT_EASEMENTS,
  LIABILITIES,
  ENDORSEMENT_LIABILITIES,
  ENTITLEMENTS,
  AREAS,
  OWNERS_SHEET,
  OWNERS_MORTGAGES,
  CORPORATE_DIAGRAM,
  BBR_SHEET,
  ENERGY_LABEL,
  ANNUAL_COMPANY_REPORT,
  LOCAL_PLAN,
  MUNICIPALITY_PLAN,
  SOIL_CON,
];

export const Template = {
  templateName: "test",
  templatePropertyType: "MAIN_PROPERTY",
  updatedAt: "27/2 - 2023. 10:50",
  owner: "Sasia",
};

const TEMPLATE_BUILDER_BBR = [BBR_SHEET];
const TEMPLATE_BUILDER_CVR = [ANNUAL_COMPANY_REPORT];
const TEMPLATE_BUILDER_ENERGY_AGENCY = [ENERGY_LABEL];
const Plandata = [LOCAL_PLAN, MUNICIPALITY_PLAN];
const TEMPLATE_BUILDER_ENVIRONMENT_AGENCY = [SOIL_CON];
const Estaid = [CORPORATE_DIAGRAM, OWNERS_MORTGAGES, OWNERS_SHEET, AREAS];
const TEMPLATE_BUILDER_DANISH_LAND_REGISTRY_CERTIFICATE = [
  ATTEST,
  ACT,
  ACT_DEED,
  DEED,
  ENDORSEMENT_DEED,
  ENTITLEMENTS,
  EASEMENTS,
  ENDORSEMENT_EASEMENTS,
  LIABILITIES,
  ENDORSEMENT_LIABILITIES,
  ATTACHMENTS,
];

export const AvailableFolderKeyItems = [
  {
    id: "CUSTOM",
    text: "",
  },
];

export const AvailableKeyItems = [
  {
    id: "CUSTOM",
    text: "",
  },
  {
    id: "Addressekaldenavn",
    text: "TEMPLATE_BUILDER_ADDRESS",
    example: "Toldbodgade 49",
  },
  {
    id: "StreetName",
    text: "TEMPLATE_BUILDER_STREET",
    example: "Toldbodgade",
  },
  {
    id: "PostCodeIdentifier",
    text: "TEMPLATE_BUILDER_ZIP_CODE",
    example: "1253",
  },
  {
    id: "ApartmentNumber",
    text: "TEMPLATE_BUILDER_CONDOMINIUM",
    example: "1",
  },
  {
    id: "Matrikelnummer",
    text: "TEMPLATE_BUILDER_CADASTRE",
    example: "370",
  },
  {
    id: "CadastralDistrictName",
    text: "TEMPLATE_BUILDER_CADASTRAL_DISTRICT",
    example: "Sankt Annæ Øster Kvarter, København",
  },
  {
    id: "CadastralDistrictIdentifier",
    text: "TEMPLATE_BUILDER_CADASTRAL_DISTRICT_IDENTIFIER",
    example: "123",
  },
  {
    id: "StreetBuildingIdentifier",
    text: "TEMPLATE_BUILDER_STREET_BUILDING_IDENTIFIER",
    example: "49A",
  },
  {
    id: "FloorIdentifier",
    text: "TEMPLATE_BUILDER_FLOOR_IDENTIFIER",
    example: "1",
  },
  {
    id: "SuiteIdentifier",
    text: "TEMPLATE_BUILDER_SUITE_IDENTIFIER",
    example: "th",
  },
  {
    id: "DistrictName",
    text: "TEMPLATE_BUILDER_DISTRICT_NAME",
    example: "København K",
  },
];

// export const AvailableDocuments = {
//   Tinglysning: [
//     {
//       description: "",
//       fileType: "pdf",
//       id: "ATTEST",
//       keys: [],
//       multiple: true,
//       name: "Tingbogsattest",
//       sourceLink: "",
//       sourceName: "",
//     },
//     {
//       description: "",
//       fileType: "pdf",
//       id: "ACT",
//       keys: [
//         {
//           example: "3_F_198",
//           id: "DokumentFilnavnTekst",
//           text: "Dokument filnavn",
//         },
//       ],
//       multiple: true,
//       name: "Akt",
//       sourceLink: "",
//       sourceName: "",
//     },
//     {
//       description: "",
//       fileType: "pdf",
//       id: "ACT_DEED",
//       keys: [
//         {
//           example: "3_F_198",
//           id: "DokumentFilnavnTekst",
//           text: "Dokument filnavn",
//         },
//       ],
//       multiple: false,
//       name: "Skøde i akt",
//       sourceLink: "",
//       sourceName: "",
//     },
//     {
//       description: "",
//       fileType: "pdf",
//       id: "DEED",
//       keys: [
//         {
//           example: "01.01.2022-0000000000",
//           id: "DokumentAliasIdentifikator",
//           text: "Dato Løbenummer",
//         },
//       ],
//       multiple: true,
//       name: "Skøde",
//       sourceLink: "",
//       sourceName: "",
//     },
//   ],
//   BBR: [
//     {
//       description: "",
//       fileType: "pdf",
//       id: "BBR_SHEET",
//       keys: [],
//       multiple: false,
//       name: "BBR-Meddelse",
//       sourceLink: "",
//       sourceName: "",
//     },
//   ],
//   CVR: [
//     {
//       description: "",
//       fileType: "pdf",
//       id: "ANNUAL_COMPANY_REPORT",
//       keys: [
//         {
//           example: "1000000",
//           id: "balance",
//           text: "Balance",
//         },
//         {
//           example: "X21-FB-94-GL",
//           id: "caseNumber",
//           text: "Sagsnummer",
//         },
//       ],
//       multiple: true,
//       name: "Ejers årsrapport",
//       sourceLink: "",
//       sourceName: "",
//     },
//   ],
//   Energistyrelsen: [
//     {
//       description: "",
//       fileType: "pdf",
//       id: "ENERGY_LABEL",
//       keys: [
//         {
//           example: "574954",
//           id: "propertyNumber",
//           text: "Ejendomsnummer",
//         },
//         {
//           example: "1",
//           id: "buildingNumber",
//           text: "Bygningsnummer",
//         },
//       ],
//       multiple: true,
//       name: "Energimærke",
//       sourceLink: "",
//       sourceName: "",
//     },
//   ],
//   Plandata: [
//     {
//       description: "",
//       fileType: "pdf",
//       id: "LOCAL_PLAN",
//       keys: [PlanKeys],
//       multiple: true,
//       name: "Lokalplan",
//       sourceLink: "",
//       sourceName: "",
//     },
//     {
//       description: "",
//       fileType: "pdf",
//       id: "MUNICIPALITY_PLAN",
//       keys: [...PlanKeys],
//       multiple: true,
//       name: "Kommuneplan",
//       sourceLink: "",
//       sourceName: "",
//     },
//   ],
//   Miljøstyrelsen: [
//     {
//       description: "",
//       fileType: "pdf",
//       id: "SOIL_CON",
//       keys: [...SoilConKeys],
//       multiple: false,
//       name: "Jordforureningsattest",
//       sourceLink: "",
//       sourceName: "",
//     },
//   ],
//   Estaid: [
//     {
//       description: "",
//       fileType: "png",
//       id: "CORPORATE_DIAGRAM",
//       keys: [...CompanyKeys],
//       multiple: true,
//       name: "Ejerskabsdiagram",
//       sourceLink: "",
//       sourceName: "",
//     },
//     {
//       description: "",
//       fileType: "xlsx",
//       id: "OWNERS_MORTGAGES",
//       keys: [],
//       multiple: false,
//       name: "Pantebreveoverblik",
//       sourceLink: "",
//       sourceName: "",
//     },
//     {
//       description: "",
//       fileType: "xlsx",
//       id: "OWNERS_SHEET",
//       keys: [],
//       multiple: false,
//       name: "Ejeroverblik",
//       sourceLink: "",
//       sourceName: "",
//     },
//     {
//       description: "",
//       fileType: "xlsx",
//       id: "AREAS",
//       keys: [],
//       multiple: false,
//       name: "Arealoverblik",
//       sourceLink: "",
//       sourceName: "",
//     },
//   ],
// };

export const documentTypes = {
  MAIN_PROPERTY: {
    TEMPLATE_BUILDER_DANISH_LAND_REGISTRY_CERTIFICATE,
    TEMPLATE_BUILDER_BBR,
    TEMPLATE_BUILDER_CVR,
    TEMPLATE_BUILDER_ENERGY_AGENCY,
    Plandata,
    TEMPLATE_BUILDER_ENVIRONMENT_AGENCY,
    Estaid,
  },
  MAIN_PROPERTY_DIVIDED_IN_CONDOS: {
    TEMPLATE_BUILDER_DANISH_LAND_REGISTRY_CERTIFICATE,
    TEMPLATE_BUILDER_BBR,
    TEMPLATE_BUILDER_CVR,
    TEMPLATE_BUILDER_ENERGY_AGENCY,
    Plandata,
    TEMPLATE_BUILDER_ENVIRONMENT_AGENCY,
    Estaid,
  },
  CONDO: {
    TEMPLATE_BUILDER_DANISH_LAND_REGISTRY_CERTIFICATE,
    TEMPLATE_BUILDER_BBR,
    TEMPLATE_BUILDER_CVR,
    TEMPLATE_BUILDER_ENERGY_AGENCY,
    Plandata,
    TEMPLATE_BUILDER_ENVIRONMENT_AGENCY,
    Estaid,
  },

  globalKeys: [...AvailableKeyItems],
  folderKeys: [...AvailableFolderKeyItems],
};

export const seperators = [
  {
    seperator: true,
    id: "CUSTOM",
    text: "(",
    example: "(",
  },
  {
    seperator: true,
    id: "CUSTOM",
    text: ")",
    example: ")",
  },
  {
    seperator: true,
    id: "CUSTOM",
    text: ".",
    example: ".",
  },
  {
    seperator: true,
    id: "CUSTOM",
    text: ",",
    example: ",",
  },
  {
    seperator: true,
    id: "CUSTOM",
    text: ":",
    example: ":",
  },
  {
    seperator: true,
    id: "CUSTOM",
    text: ";",
    example: ";",
  },
  {
    seperator: true,
    id: "CUSTOM",
    text: "-",
    example: "-",
  },
  {
    seperator: true,
    id: "CUSTOM",
    text: "_",
    example: "_",
  },
  {
    seperator: true,
    id: "CUSTOM",
    text: "[",
    example: "[",
  },
  {
    seperator: true,
    id: "CUSTOM",
    text: "]",
    example: "]",
  },
];
